import React from "react";
import "./App.css";

const Portrait = () => {

    return (
        <div className="block-opener portrait">
        </div>
    )

}

export default Portrait;