// eslint-disable-next-line
import React from "react";
import "../App.css";

const Skills = () => {
  return (
    <div className="block skills section-hidden block-full" id="skillsContent">
      <ul>
        <li>PTC Creo</li>
        <li>Solidworks</li>
        <li>NX</li>
        <li>International manufacturing</li>
        <li>Program management</li>
        <li>Project management</li>
        <li>Electronic packaging</li>
        <li>Injection molding</li>
        <li>Sheet metal</li>
        <li>Adhesives</li>
        <li>PSA</li>
        <li>VHB</li>
        <li>DFM</li>
        <li>DFA</li>
        <li>Manufacturing automation</li>
        <li>Design of experiments</li>
        <li>FEA/simulation</li>
        <li>3D printing</li>
        <li>Surface modeling</li>
        <li>GD&T</li>
        <li>CpK analysis</li>
        <li>Tolerance analysis</li>
        <li>FEA</li>
        <li>JMP</li>
        <li>JavaScript ES6</li>
        <li>JSX</li>
        <li>React.js</li>
        <li>TypeScript</li>
        <li>CSS</li>
        <li>Responsive design</li>
        <li>Sass</li>
        <li>AWS Lambda</li>
        <li>DynamoDB</li>
        <li>Cognito</li>
        <li>API Gateway</li>
        <li>Python</li>
        <li>RESTful APIs</li>
        <li>JSON</li>
        <li>Git</li>
        <li>Jest</li>
        <li>VSCode</li>
        <li>SEO</li>
        <li>Swift</li>
        <li>C</li>
        <li>C++</li>
        <li>Adobe Creative Cloud</li>
      </ul>
    </div>
  );
};

export default Skills;
